.CardContainer {
    width: 60vw;
    margin-top: 40px;
}

.Header {
    width: 100%;
}

.User {
    margin-left: 20px;
    color: rgb(103, 110, 103);
}

.PostDateTime {
    font-size: calc(2px + 2vmin);
    color: rgb(121, 141, 105)
}

.ShowCommentsText:hover {
    color: rgb(95, 95, 95);
    cursor: pointer;
    font-weight: 500;
}

.CommentsSection {
    padding: 0;
}