body {
    background: linear-gradient(to right, #DECBA4, #3E5151);
}

.AllPosts {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.LoadingDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.HomeButton {
    width: 6vw;
    background-color: rgb(63, 141, 100);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 4vh;
    font-size: calc(5px + 2vmin);
    cursor: pointer;
    color: white;
    border-radius: 10px;
    text-decoration: none;
}

.HomeButton:hover {
    background-color: rgb(39, 88, 62);
    -webkit-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    color: white;
}

.NoPosts {
    margin-top: 100px;
    font-weight: 300;
    font-size: calc(15px + 2vmin);
}