.CommentSubmission {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
}

.CommentSubmit {
    margin-left: 20px;
    flex: 1;
    width: 12vw;
    border: 0 !important;
    background-color: rgb(63, 141, 100) !important;
    cursor: pointer;
    color: white !important;
    border-radius: 10px !important;
    text-decoration: none;
}

.CommentSubmitText {
    font-size: 14px;
}

.CommentContent {
    flex: 15;
    min-height: 8vh;
    max-height: 8vh;
}