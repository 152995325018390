body {
    background: url('../../assets/napali_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.HomeButton {
    width: 6vw;
    background-color: rgb(63, 141, 100);
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 4vh;
    font-size: calc(5px + 2vmin);
    cursor: pointer;
    color: white;
    border-radius: 10px;
    text-decoration: none;
}

.HomeButton:hover {
    background-color: rgb(39, 88, 62);
    -webkit-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    color: white;
}

.PostForm {
    margin-top: 70px;
    opacity: 0.8;
}

.ShareText {
    font-size: calc(16px + 2vmin);
    font-weight: 300;
}

.PostTitle {
    margin-top: 20px;
}

.PostContent {
    margin-top: 20px;
    height: 45vh;
}

.PostSubmitButtons {
    width: 40%;
    display: flex;
}

.PostSubmitButton {
    flex: auto;
    width: 12vw;
    border: 0 !important;
    background-color: rgb(63, 141, 100) !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 5vh;
    font-size: calc(7px + 2vmin) !important;
    cursor: pointer;
    color: white !important;
    margin-top: 30px;
    border-radius: 10px !important;
    text-decoration: none;
}

.PostSubmitButton:hover {
    background-color: rgb(39, 88, 62);
    -webkit-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    color: white;
}

.CancelButton {
    margin-left: 30px;
}