.WGNavBar {
    background-color: rgb(163, 189, 142);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 4px 0 rgba(0, 0, 0, 0.19);
}

.WGBrand {
    font-family: countryside;
    font-size: calc(12px + 2vmin);
}

.NavBarItem {
    margin-left: 20px;
    text-decoration: none;
    color: black;
}

.NavBarItem:hover {
    color: black;
}